import React from 'react';
import {
    Box,
    Container,
    Link,
    Grid,
    Typography,
    withStyles
} from '@material-ui/core';
import { withTranslation } from 'react-i18next';

const styles = (theme) => ({
    root: {
        paddingTop: '20px',
        paddingBottom: '20px',
    },
    middle: {
        [theme.breakpoints.up('md')]: {
            margin: '0 30px'
        },
        [theme.breakpoints.down('md')]: {
            margin: '0 15px'
        }
    },
    link: {
        '&:hover': {
            opacity: 0.6
        }
    },
    copyRights: {
        [theme.breakpoints.up('md')]: {
            textAlign: 'right'
        },
        [theme.breakpoints.down('md')]: {
            textAlign: 'center'
        }
    },
    socialContainer: {
        textAlign: 'center'
    }
});

class Footer extends React.Component {
    render() {
        const { classes, t } = this.props;

        return (
            <div className={classes.root}>
                <Container maxWidth="lg">
                    <Grid container>
                        <Grid item md={6} xs={12}>
                            <Box display="flex" flexWrap="wrap" className={classes.socialContainer}>
                                <Typography variant="body2" color="textSecondary">
                                    <Link
                                        color="textSecondary"
                                        className={classes.link}
                                        href="https://www.facebook.com/Mohamed.Atef20172016"
                                        target="_blank"
                                    >
                                        {t('fb')}
                                    </Link>
                                </Typography>
                                <Typography variant="body2" color="textSecondary" className={classes.middle}>
                                    <Link
                                        color="textSecondary"
                                        className={classes.link}
                                        href="https://twitter.com/Mohamed38431985"
                                        target="_blank"
                                    >
                                        {t('twitter')}
                                    </Link>
                                </Typography>
                                <Typography variant="body2" color="textSecondary">
                                    <Link
                                        color="textSecondary"
                                        className={classes.link}
                                        href="https://www.linkedin.com/in/mohamed-atef-aa1014138/"
                                        target="_blank"
                                    >
                                        {t('linked_in')}
                                    </Link>
                                </Typography>
                                <Typography variant="body2" color="textSecondary">
                                    <Link
                                        color="textSecondary"
                                        className={classes.link}
                                        href="https://vk.com/id833200469"
                                        target="_blank"
                                    >
                                        {t('vk')}
                                    </Link>
                                </Typography>
                            </Box>
                        </Grid>
                        <Grid item md={6} xs={12}>
                            <Typography variant="body2" className={classes.copyRights} color="textSecondary">
                                © {new Date().getFullYear()} {t('copy_rights')}
                            </Typography>
                        </Grid>
                    </Grid>
                </Container>
            </div>
        );
    }
}

export default withStyles(styles)(
    withTranslation()(Footer)
);