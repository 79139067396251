import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';

i18n
  .use(LanguageDetector) // Detect user language
  .use(initReactI18next) // Passes i18n instance to react-i18next
  .init({
    detection: {
      order: ['path', 'localStorage', 'navigator'], // Detect language from URL path first
      lookupFromPathIndex: 0, // Get the first part of the path (e.g., /ru)
      caches: ['localStorage'] // Cache the language selection in localStorage
    },
    fallbackLng: 'en', // Fallback language
    lng: 'en', // Default language
    debug: true, // Debug mode (disable in production)
    resources: {
      en:{
        translation: {
            "nav": {
                "about": "About",
                "what_i_do": "What I Do",
                "skills": "Skills",
                "testimonial": "Testimonial",
                "portfolio": "Portfolio"
            },
            "mohamed_atef": "Mohamed Atef",
            "job_title": "Web Developer",
            "job_description": "I am professional web developer with over 6 years of experience in web development and wide knowledge in HTML5, CSS3, SCSS, JS, jQuery, Reactjs, Angular, Nextjs, PHP, Mysql, Laravel, AWS EC2. I am hardworking and always do my best to finish my tasks and deliver them on time, with high quality and accuracy and I am talented in handling code errors ....and after these years of experience, I learned that it all about satisfying the customer. Your satisfaction is my Duty.",
            "contact_me": "CONTACT ME",
            "additional_information": "Additional Information",
            "dob": "Born on 22 March 1999",
            "years_old": "years old",
            "born_place": "Born in Port Said, Egypt",
            "live_now": "(Live in Novgorod Oblast, Russia)",
            "education": "Graduated from Faculty of Management and Information system",
            "graduation_year": "Graduation Year",
            "graduation_date": "January 2023",
            "languages": "Languages",
            "arabic": "Arabic",
            "native": "Native",
            "english": "English",
            "russian": "Russian",
            "fluent": "Fluent",
            "intermediate": "Level B2",
            "what_i_do": "What I Do",
            "what_i_do_text": "I work with clients & business owners to move their websites from virtual design phase or idea to live and interactive website, through my Skills with my talent & hardworking I will provide a magical service & professional standards which fits to your requirements.",
            "prefect_from": "“Perfect From Beginning to End!“",
            "get_in_touch_btn": "GET IN TOUCH",
            "get_in_touch_title": "Get in touch",
            "web_developer_with": "Web Developer with extra skills",
            "until": "Until",
            "since": "Since",
            "testimonial": "Testimonial",
            "portfolio": "Portfolio",
            "developed_from": "Developed from",
            "name": "Name",
            "email": "Email",
            "phone": "Phone",
            "website_url": "Website URL",
            "reason": "Reason of contacting me",
            "message": "Your Message",
            "send": "SEND",
            "fb": "Facebook",
            "twitter": "Twitter",
            "linked_in": "Linkedin",
            "vk": "VK",
            "copy_rights": "Mo Atef All Rights Reserved.",
            "form": {
              "success": "Thank you for contacting me I will replay as soon as possible"
            },
            "validation": {
              "name_required": "Name is required",
              "email_invalid": "Must be a valid email",
              "email_required": "Email is required",
              "phone_required": "Phone is required",
              "website_invalid": "Enter a valid URL",
              "reason_required": "Reason for sending the message is required",
              "message_required": "Message is required",
              "recaptcha_required": "reCAPTCHA is required",
              "submit_error": "Error cannot handle your request"
            },
            "cookies": {
              "agree": "I Agree",
              "message": "We use Cookies to ensure that we give you the best experience on our website."
            }    
        }
      },
      ru: {
        translation: {
          "nav": {
            "about": "Обо мне",
            "what_i_do": "Чем я занимаюсь",
            "skills": "Навыки",
            "testimonial": "Отзывы",
            "portfolio": "Портфолио"
          },
          "mohamed_atef": "Мохамед Атеф",
          "job_title": "Веб-разработчик",
          "job_description": "Я профессиональный веб-разработчик с более чем 6-летним опытом работы в веб-разработке и обширными знаниями в HTML5, CSS3, SCSS, JS, jQuery, Reactjs, Angular, Nextjs, PHP, Mysql, Laravel, AWS EC2. Я трудолюбивый и всегда стараюсь завершить задачи вовремя, с высоким качеством и точностью. У меня есть талант к устранению ошибок в коде. После всех этих лет опыта я понял, что все дело в удовлетворении клиента. Ваше удовлетворение - моя обязанность.",
          "contact_me": "СВЯЗАТЬСЯ СО МНОЙ",
          "additional_information": "Дополнительная информация",
          "dob": "Родился 22 марта 1999 года",
          "years_old": "лет",
          "born_place": "Родился в Порт-Саиде, Египет",
          "live_now": "(Проживаю в Новгородской области, Россия)",
          "education": "Выпускник факультета управления и информационных систем",
          "graduation_year": "Год выпуска",
          "graduation_date": "Январь 2023",
          "languages": "Языки",
          "arabic": "Арабский",
          "native": "Родной",
          "english": "Английский",
          "russian": "Русский",
          "fluent": "Свободно",
          "intermediate": "Уровень B2",
          "what_i_do": "Чем я занимаюсь",
          "what_i_do_text": "Я работаю с клиентами и владельцами бизнеса, чтобы превратить их веб-дизайны или идеи в живые и интерактивные веб-сайты. Благодаря своим навыкам, таланту и трудолюбию я предоставлю вам магическое обслуживание и профессиональные стандарты, которые будут соответствовать вашим требованиям.",
          "prefect_from": "“Идеально с самого начала до конца!“",
          "get_in_touch_btn": "СВЯЗАТЬСЯ",
          "get_in_touch_title": "Свяжитесь со мной",
          "web_developer_with": "Веб-разработчик с дополнительными навыками",
          "until": "дое",
          "since": "С",
          "testimonial": "Отзывы",
          "portfolio": "Портфолио",
          "developed_from": "Разработано из",
          "name": "Имя",
          "email": "Электронная почта",
          "phone": "Телефон",
          "website_url": "URL веб-сайта",
          "reason": "Причина обращения ко мне",
          "message": "Ваше сообщение",
          "send": "ОТПРАВИТЬ",
          "fb": "Фейсбук",
          "twitter": "Твиттер",
          "linked_in": "Линкедин",
          "vk": "ВКонтакте",
                    "copy_rights": "Мохамед Атеф. Все права защищены.",
          "form": {
            "success": "Спасибо, что связались со мной. Я отвечу как можно скорее."
          },
          "validation": {
            "name_required": "Имя обязательно",
            "email_invalid": "Должен быть действительный адрес электронной почты",
            "email_required": "Электронная почта обязательна",
            "phone_required": "Телефон обязателен",
            "website_invalid": "Введите действительный URL-адрес",
            "reason_required": "Укажите причину отправки сообщения",
            "message_required": "Сообщение обязательно",
            "recaptcha_required": "Требуется reCAPTCHA",
            "submit_error": "Ошибка, невозможно обработать ваш запрос"
          },
          "cookies": {
            "agree": "Я согласен",
            "message": "Мы используем файлы cookie, чтобы обеспечить вам лучший опыт на нашем веб-сайте."
          }         
        }
      }
      
    },
    interpolation: {
      escapeValue: false, // React already escapes by default
    },
  });

export default i18n;
