import React, { Suspense, Fragment, lazy } from 'react';
import { Switch, Redirect, Route } from 'react-router-dom';
import MainLayout from 'src/layouts/MainLayout';
import LoadingScreen from 'src/components/LoadingScreen';
import { useTranslation } from 'react-i18next';
import i18n from './i18n';

export const renderRoutes = (routes = []) => (
  <Suspense fallback={<LoadingScreen />}>
    <Switch>
      {routes.map((route, i) => {
        const Guard = route.guard || Fragment;
        const Layout = route.layout || Fragment;
        const Component = route.component;

        return (
          <Route
            key={i}
            path={route.path}
            exact={route.exact}
            render={(props) => {
              // Set language based on the URL
              const lang = props.match.params.lang; // Extract language from URL
              if (lang) {
                i18n.changeLanguage(lang); // Update language in i18next
              }

              return (
                <Guard>
                  <Layout>
                    {route.routes
                      ? renderRoutes(route.routes)
                      : <Component {...props} />}
                  </Layout>
                </Guard>
              );
            }}
          />
        );
      })}
    </Switch>
  </Suspense>
);


// Functional component to handle language redirection
const LanguageRedirect = () => {
  const { i18n } = useTranslation();
  const userLang = navigator.language.split('-')[0]; // Detect browser language
  const defaultLang = ['en', 'ru'].includes(userLang) ? userLang : 'en';
  i18n.changeLanguage(defaultLang); // Change language in i18next
  return <Redirect to={`/${defaultLang}`} />;
};

const routes = [
  {
    exact: true,
    path: '/404',
    component: lazy(() => import('src/views/errors/NotFoundView'))
  },
  {
    path: '/:lang(en|ru)',
    layout: MainLayout,
    routes: [
      {
        exact: true,
        path: '/:lang(en|ru)',
        component: lazy(() => import('src/views/Home'))
      },
      {
        component: () => <Redirect to="/404" />
      }
    ]
  },
  {
    exact: true,
    path: '/',
    component: LanguageRedirect // Redirect to language-specific path
  }
];

export default routes;