import React, { useState } from 'react';
import { Menu, MenuItem, Button } from '@material-ui/core';
import i18n from '../i18n'; // Import your i18n instance
import { useHistory, useParams } from 'react-router-dom';

const LanguageDropdown = () => {
  const [anchorEl, setAnchorEl] = useState(null);
  const history = useHistory();
  const { lang } = useParams();
  const [currentLanguage, setCurrentLanguage] = useState(lang? lang: 'en'); // Default language
  
  const languageOptions = [
    { code: 'en', name: 'English', flag: 'https://hatscripts.github.io/circle-flags/flags/us.svg' },
    { code: 'ru', name: 'Russian', flag: 'https://hatscripts.github.io/circle-flags/flags/ru.svg' },
    // { code: 'ar', name: 'Arabic', flag: 'https://hatscripts.github.io/circle-flags/flags/eg.svg' },
  ];

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const changeLanguage = (languageCode) => {
    i18n.changeLanguage(languageCode); // Change the language in i18n
    setCurrentLanguage(languageCode); // Update the selected language
    handleClose();
      if (languageCode !== lang) {
        i18n.changeLanguage(languageCode);
        history.push(`/${languageCode}`);
      }
  };

  const currentLanguageFlag = languageOptions.find(
    (lang) => lang.code === currentLanguage
  )?.flag;

  return (
    <div>
      <Button
        aria-controls="language-menu"
        aria-haspopup="true"
        onClick={handleClick}
        style={{ textTransform: 'none', display: 'flex', alignItems: 'center' }}
      >
        <img
          src={currentLanguageFlag}
          alt={currentLanguage}
          style={{ width: 20, marginRight: 8 }}
        />
      </Button>
      <Menu
        id="language-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        {languageOptions.map((language) => (
          <MenuItem
            key={language.code}
            onClick={() => changeLanguage(language.code)}
          >
            <img
              src={language.flag}
              alt={language.name}
              style={{ width: 20, marginRight: 10 }}
            />
            {language.name}
          </MenuItem>
        ))}
      </Menu>
    </div>
  );
};

export default LanguageDropdown;
