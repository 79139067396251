import React from 'react';

const Logo = (props) => {
  return (
    <img
      alt="Mo Atef Logo"
      src="/static/mo-atef.png"
      style={{maxHeight: '50px'}}
      {...props}
    />
  );
}

export default Logo;
